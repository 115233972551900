<template>
    <div>
      <div v-if="!feedItems" class="pa-8">
        <i><v-icon>fa fa-circle-notch fa-spin</v-icon> Loading feed...</i>
      </div>
      <v-card-text v-else-if="feedItems.length == 0" class="">
        <i>{{ emptyLabel || 'Empty feed.' }}</i>
      </v-card-text>
      <v-list v-else-if="compact">
        <template v-for="(item, idx) in feedItems">
          <v-divider :key="`div${idx}`"/>
          <v-list-item :key="idx" two-line>
            <v-list-item-avatar v-if="item.profile && item.profile.img"><v-img :src="item.profile.img" /></v-list-item-avatar> 
            <v-list-item-avatar v-if="item.sender && item.sender.img"><v-img :src="item.sender.img" /></v-list-item-avatar> 
            <v-list-item-content>
              <v-list-item-title>
                {{ item.msg }}
                  <div v-if="item.video">
                    <div v-if="item.meta && item.meta.some(x => x.key == 'apivideo.id')">
                      <ApiVideoPlayer :videoId="item.meta.find(x => x.key == 'apivideo.id').val_str" />  
                    </div>
                    <div v-else>
                      <p class="text-muted">Inline video player is only available in the app.</p>
                      <v-btn outlined :href="item.video" target="_blank">Watch Video</v-btn>
                    </div>
                  </div>
              </v-list-item-title>
              <v-list-item-subtitle>
                <span v-if="item.profile">By {{ item.profile.name }} •</span> 
                <span v-if="item.sender">By {{ item.sender.name }} •</span> 
                {{ item.timestamp | localDate('ddd L LTS Z') }}
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="showLikeOption || showCommentOption || allowDelete" class=" pt-0">
                <v-btn v-if="showLikeOption" small @click="likeItem(item.id)" text color="primary">
                  <v-icon color="primary" x-small class="me-1">fa-thumbs-up</v-icon><strong>{{ item.likes }}</strong> {{$t('results.details.likes')}}
                </v-btn>
                <v-btn v-if="showCommentOption" small @click="openItemDetails(item)" text color="primary">
                  <v-icon color="primary" x-small class="me-1">fa-comments</v-icon><strong>{{ item.comments }}</strong> 
                  {{$t('results.details.comments')}}
                </v-btn>
                <v-spacer v-if="allowDelete"/>
                <v-btn v-if="allowDelete && ((me && item.profile && me.id == item.profile.id) || adminView)" small icon color="error" @click="deleteFeedItem(item.id)"><v-icon small>fa-trash</v-icon></v-btn>            </v-list-item-action>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider v-if="idx == feedItems.length-1" :key="`div${idx}_end`" class="mb-2"/>
        </template>
      </v-list>
      <v-timeline v-else class="mx-4 pt-0" :dense="$vuetify.breakpoint.xsOnly">
          <template v-for="(group, category) in feedItemsByDate">
            <v-timeline-item :key="category" hide-dot class="date-separator">
              <span>{{group.key | localDate("L")}}</span> 
            </v-timeline-item>

            <v-timeline-item
              v-for="(item, idx) in group.data"
              :key="`${category}-${idx}`"
              color="green"
              :small="$vuetify.breakpoint.xsOnly"
              :icon="item.tile ? $helpers.getActivityIcon(item.tile.activity_type) : null"
            >
              <template v-slot:opposite>
                <span>&nbsp;</span>
              </template>
              <v-card v-if="item.type == 'TILE_RANKING'" color="grey lighten-5" class="elevation-1">
                <v-card-title class="subtitle-1 pa-2 pb-0">
                  <strong>#{{ item.tile.pos }}</strong> <span class="grey--text mx-1">(of {{item.tile.cnt}})</span> in
                  <router-link class="ml-1" :to="{name: 'tile', params: { id:item.tile.id, type: item.tile.activity_type.toLowerCase() }, query: { ranking: item.tile.key }}">{{item.tile.name}}</router-link>
                </v-card-title>
                <v-card-text class="pa-2 pb-0">{{ item.msg }}</v-card-text>
                <v-card-actions class="pa-2 pt-0">
                  <v-btn text color="primary" :to="{name: 'tile', params: { id:item.tile.id, type: item.tile.activity_type.toLowerCase()}, query: { ranking: item.tile.key }}">View tile</v-btn>
                  <v-spacer/>
                  <ShareButton :text="`${item.share_msg || item.msg} - Check sodisp.com`" color="primary" :route="{name: 'tile', params: { id:item.tile.id, type: item.tile.activity_type.toLowerCase()}, query: { ranking: item.tile.key }}" />
                </v-card-actions>
              </v-card>
              <v-card v-else-if="item.type == 'EVENT_UPDATE' || item.type == 'EVENT_PHOTO' || item.type == 'GENERIC'" color="grey lighten-5" class="elevation-1">
                <v-card-text class="pa-2 pb-0">
                  <div v-if="adminView">
                    <p v-if="item.lang" class="text-muted float-right">Language: <strong>{{item.lang}}</strong></p>
                    <p class="text-muted mb-0">{{item.timestamp | localDate}}</p>
                  </div>
                  <vue-markdown class="markdown" :html="false" :source="item.msg" />
                  <img v-if="item.img && !item.video" :src="item.img" class="feed-image-lg mt-4" />
                  <div v-if="item.video">
                    <div v-if="item.meta && item.meta.some(x => x.key == 'apivideo.id')">
                      <ApiVideoPlayer :videoId="item.meta.find(x => x.key == 'apivideo.id').val_str" />  
                    </div>
                    <div v-else>
                      <p class="text-muted">Inline video player is only available in the app.</p>
                      <v-btn outlined :href="item.video" target="_blank">Watch Video</v-btn>
                    </div>
                  </div>
                </v-card-text>
                <v-divider  v-if="event && event.allow_feed_interactions" class="my-2"/>
                <v-card-actions v-if="event && event.allow_feed_interactions" class="pa-2 pt-0">
                  <span><v-icon color="grey" small class="mr-2">fa-thumbs-up</v-icon><strong>{{ item.likes }}</strong> {{$t('results.details.likes')}}</span>
                  <v-spacer/>
                  <v-btn v-if="$listeners && $listeners.openItemComments" @click="openItemComments(item.id)" outlined color="primary">
                    <v-icon color="primary" small class="mr-2">fa-comments</v-icon><strong>{{ item.comments }}</strong> 
                    {{$t('results.details.comments')}}
                  </v-btn>
                  <span v-else>
                    <v-icon color="grey" small class="mr-2">fa-comments</v-icon><strong>{{ item.comments }}</strong> 
                    {{$t('results.details.comments')}}
                  </span>
                </v-card-actions>
                <v-card-actions class="pa-2 pt-0">
                    <v-btn v-if="item.link" text color="primary" class="pl-0" :href="item.link" target="_blank" ref="nofollow">{{$t('shared.view')}}</v-btn>
                    <v-btn v-if="item.race && item.race.race_id" text color="primary" class="pl-0" exact :to="{name: 'race', params: { id:item.race.event_id, raceId: item.race.race_id}}">{{$t('profile.feed.view-race')}}</v-btn>
                    <!--<v-btn v-else-if="item.race" text color="primary" class="pl-0" exact :to="{name: 'event', params: { id:item.race.event_id}}">{{$t('shared.view')}}</v-btn>-->
                    <v-spacer/>
                    <v-btn v-if="allowDelete && (adminView || (me && item.profile && me.id == item.profile.id))" small icon color="error" @click="deleteFeedItem(item.id)"><v-icon small>fa-trash</v-icon></v-btn>
                </v-card-actions>
              </v-card>
              <v-card v-else-if="item.race && item.race.result_id" color="grey lighten-5" class="elevation-1 d-flex flex-row">
                <div v-if="item.img" class="flex-shrink-1 pa-2">
                  <img :src="item.img" class="feed-image" />
                </div>
                <div class="flex-grow-1 ">
                  <v-card-title class="subtitle-1 pa-2 pb-0">
                    <strong>{{ item.race.event_name }}</strong>
                    <!-- <router-link class="ml-1" :to="{name: 'raceResults', params: { id:item.race.event_id, raceId: item.race.race_id }}">{{item.race.race_name}}</router-link> -->
                  </v-card-title>
                  <v-card-text class="pa-2 pb-0">{{ item.msg }}</v-card-text>
                  <v-card-actions class="pa-0">
                    <v-btn text color="primary" class="pl-0" exact :to="{name: 'raceResult', params: { id:item.race.event_id, raceId: item.race.race_id, resultId: item.race.result_id}}">{{$t('shared.view')}}</v-btn>
                    <v-spacer/>
                    <v-btn v-if="allowDelete && me && item.profile && me.id == item.profile.id" small icon color="error" @click="deleteFeedItem(item.id)"><v-icon small>fa-trash</v-icon></v-btn>
                    <ShareButton icon :text="`${item.share_msg || item.msg} - Check sodisp.com`" color="primary" :route="{name: 'raceResult', params: { id:item.race.event_id, raceId: item.race.race_id, resultId: item.race.result_id}}" />
                  </v-card-actions>
                </div>
              </v-card>
              <!-- <v-card v-else-if="item.type == 'EVENT_PHOTO' && item.img" color="grey lighten-5" class="elevation-1 d-flex flex-row">
                <div class="pa-2">
                  <img :src="item.img" style="max-width:100%;" />
                </div>
              </v-card> -->
              <v-card v-else-if="item.race && item.race.event_id" color="grey lighten-5" class="elevation-1 d-flex flex-row">
                <div v-if="item.img" class="flex-shrink-1 pa-2">
                  <img :src="item.img" class="feed-image" />
                </div>
                <div class="flex-grow-1 ">
                  <v-card-title class="subtitle-1 pa-2 pb-0">
                    <strong>{{ item.race.event_name }}</strong>
                  </v-card-title>
                  <v-card-text class="pa-2 pb-0">{{ item.msg }}</v-card-text>
                  <v-card-actions class="pa-0">
                    <v-btn text color="primary" class="pl-0" exact :to="{name: 'event', params: { id:item.race.event_id}}">{{$t('shared.view')}}</v-btn>
                    <v-spacer/>
                    <v-btn v-if="allowDelete && me && item.profile && me.id == item.profile.id" small icon color="error" @click="deleteFeedItem(item.id)"><v-icon small>fa-trash</v-icon></v-btn>
                    <ShareButton icon :text="`${item.share_msg || item.msg} - Check sodisp.com`" color="primary" :route="{name: 'event', params: { id:item.race.event_id }}" />
                  </v-card-actions>
                </div>
              </v-card>
              <v-card v-else color="grey lighten-5" class="elevation-1">
                
                <div v-if="(me && item.profile && me.id !== item.profile.id) || item.type == 'FEED_ITEM_COMMENT'">
                  <v-list-item v-if="item.profile">
                    <v-list-item-avatar v-if="item.profile && item.profile.img"><v-img :src="item.profile.img" /></v-list-item-avatar> 
                    <v-list-item-title>{{item.profile.name}}</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="item.sender">
                    <v-list-item-avatar v-if="item.sender.img"><v-img :src="item.sender.img" /></v-list-item-avatar> 
                    <v-list-item-title>{{item.sender.name}}</v-list-item-title>
                  </v-list-item>
                  <v-divider />
                </div>
                <v-card-text class="pa-2 text-muted">{{ item.timestamp | localDate('ddd L LTS Z') }}</v-card-text>
                <v-card-text class="pa-2">{{ item.msg }}</v-card-text>
                <v-card-actions class="pa-0">
                    <v-btn v-if="showLikeOption" @click="likeItem(item.id)" text color="primary">
                      <v-icon color="primary" small class="mr-2">fa-thumbs-up</v-icon><strong>{{ item.likes }}</strong> {{$t('results.details.likes')}}
                    </v-btn>
                    
                    <v-spacer/>
                    <v-btn v-if="showCommentOption" @click="openItemDetails(item)" text color="primary">
                      <v-icon color="primary" small class="mr-2">fa-comments</v-icon><strong>{{ item.comments }}</strong> 
                      {{$t('results.details.comments')}}
                    </v-btn>
                    <v-spacer  v-if="allowDelete"/>
                    <v-btn v-if="allowDelete && me && item.profile && me.id == item.profile.id" small icon color="error" @click="deleteFeedItem(item.id)"><v-icon small>fa-trash</v-icon></v-btn>
                </v-card-actions>
              </v-card>
            </v-timeline-item>

          </template>
        </v-timeline>

        <v-dialog v-model="feedItemDetailsDialog">
          <v-card>
            <v-card-title class="subtitle">Comments</v-card-title>
            <FeedList :feedItems="detailsItems" compact :emptyLabel="$t('results.comments.no-comments-message')" />
            <v-divider />
            <PostCommentField @submit="postCommentOnItem" class="pa-2" />
          </v-card>
        </v-dialog>
    </div>    
</template>

<script>
import { mapGetters } from "vuex";
import Vue from "vue";
import { DateTime } from 'luxon';
import ShareButton from '@/components/ShareButton.vue';
import VueMarkdown from '@/components/VueMarkdown.vue'
import PostCommentField from '@/components/PostCommentField.vue'
import ApiVideoPlayer from "@/components/generic/ApiVideoPlayer.vue";

export default {
  name: "FeedList",
  components: {
    ShareButton,
    VueMarkdown,
    PostCommentField,
    ApiVideoPlayer,
  },
  props: {
    me: Object,
    emptyLabel: String,
    feedItems: Array,
    allowDelete: Boolean,
    event: Object,
    adminView: Boolean,
    compact: Boolean,
    //refreshFn: Function,
    //likeItemFn: Function,
    getItemCommentsFn: Function,
    postCommentOnItemFn: Function,
  },
  data: function() {
    return {
      feedItemDetailsDialog: false,
      selectedItem: null,
      detailsItems: null,
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
      deleteFeedItem(id) {
        this.$emit('deleteItem', id);
      },
      openItemComments(id) {
        this.$emit('openItemComments', id);
      },
      async openItemDetails(item) {
        this.detailsItems = null;
        this.feedItemDetailsDialog = true;
        this.selectedItem = item;
        this.detailsItems = await this.getItemCommentsFn(item.id);
      },
      likeItem(id) {
        this.$emit('likeItem', id);
      },
      async postCommentOnItem(message) {
        await this.postCommentOnItemFn(this.selectedItem.id, message);
        this.detailsItems = await this.getItemCommentsFn(this.selectedItem.id);
      },
  },
  computed: {
    showLikeOption(){
      return this.$listeners && this.$listeners.likeItem && (!this.event || this.event.allow_feed_interactions);
    },
    showCommentOption(){
      return this.getItemCommentsFn && (!this.event || this.event.allow_feed_interactions);
    },
    feedItemsByDate(){
      var result = this.feedItems.reduce((acc, item) => {
        const date = DateTime.fromISO(item.timestamp).toISODate();
        (acc[date] = acc[date] || []).push(item)
        return acc
      }, {});
      const sorted = [];
      var keys = Object.keys(result);
      keys.sort();; // or loop over the object to get the array
      keys.reverse();
      for (var key of keys) {
        sorted.push({key: key, data: result[key]});
      }
      return sorted;
    },
    ...mapGetters({
      user: "user"
    })
  },
  watch: {
  }
};
</script>

<style lang="scss">
  .v-timeline {
    .v-timeline-item { margin-top: -15px;  }
    .v-timeline-item:first-child { margin-top: 0; }
    .v-timeline-item.date-separator {
      .v-timeline-item__body { 
        max-width: 100% !important; text-align: center; background: white; font-weight: bold; 
        span { border-top: solid 2px rgba(0, 0, 0, 0.12); border-bottom: solid 2px rgba(0, 0, 0, 0.12); }
      }
      .v-timeline-item__divider { display: none !important; }
    }
    .feed-image { max-height: 100px; max-width: 100px; }
    .feed-image-lg { max-height: 150px; max-width: 100%; }
  }
  .v-application--is-ltr .v-timeline--dense:not(.v-timeline--reverse):before {left: 15px;}
  .v-timeline--dense {
    .v-timeline-item { margin-top: 0; }
    .v-timeline-item__divider { min-width: 30px;}
    .v-timeline-item__body { max-width: calc(100% - 30px); }
    .v-timeline-item.date-separator { 
      .v-timeline-item__body { 
        text-align: left;
      }
    }
    .feed-image { max-height: 75px; max-width: 75px; }
  }
</style>